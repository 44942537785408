import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {Breadcrumb, Layout, Seo, Share} from '@app/components';

import sbbImage from '@app/assets/images/sbb.jpg';

const Tag: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.Tag',
  };
  const {t} = useLocale();

  return (
    <Layout>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="container mx-auto px-4 pt-20 pb-5 lg:px-0 xl:max-w-container-xl text-lg">
        <img className="m-auto" src={sbbImage} />

        <h1 className="mt-5 mb-5 font-bold">Dr. Serdar Bora Bayraktaroğlu</h1>

        <div className="who-am-i-description">
          <p className="mb-4">
            Serdar Bora Bayraktaroğlu 2005 yılında Cerrahpaşa Tıp Fakültesi
            İngilizce Tıp Programı'ndan mezun oldu. Plastik cerrahi eğitimini
            2005-2011 yılları arasında Şişli Etfal Hastanesi Plastik
            Rekonstruktif ve Estetik Cerrahi Kliniği’nde tamamladı. Aynı
            klinikte ‘Mikrocerrahi ve El Cerrahisi’ eğitimini bitirdi. Dr.
            Bayraktaroğlu, 2009 yılı ocak ayında, Amerika’da California-LA’ da
            The Facial Paralysis Institute’de altı aylık ‘Yüz Estetiği’ stajını
            başarıyla tamamladı.
          </p>
          <p className="mb-4">
            Mikrovasküler cerrahi alanında tezini tamamlayan Dr. Bayraktaroğlu,
            2011’den bu yana Plastik Rekonstruktif ve Estetik Cerrahi Uzmanı
            olarak çalışıyor.
          </p>
          <p className="mb-4">
            Akademik kariyerini kök hücre doktorasıyla zenginleştiren Dr.
            Bayraktaroğlu, aynı zamanda ilgi alanı olan felsefe bölümünde de
            lisans öğrencisi.
          </p>
          <p className="mb-4">
            Türk Plastik ve Rekonstruktif Cerrahi Derneği ve ISAPS (
            international society of aesthetic plastic surgeons derneği) üyesi
            olan Dr. Bayraktaroğlu, çok sayıda ulusal ve uluslararası
            organizasyonda estetik plastik cerrahiye dair sunumlar yapıyor;
            uluslararası platformlarda antiaging ve medikal estetik konularında
            dünyadaki gelişmeleri yakından takip ediyor.
          </p>
          <p className="mb-8">
            Tıp alanındaki profesyonel çalışmalarının yanında deneyimlerini ve
            yıllardır süren araştırmalarını paylaştığı “Filtresiz Bir Dünya
            Mümkün” isimli kitabı; ‘Bir Plastik Cerrahın Aykırı Karalama
            Defteri’ notuyla 2024 Ocak’ta Doğan Kitap’tan çıktı. Estetik plastik
            cerrahiye dair çarpıcı konuları gündemine aldığı ikinci kitabı ise
            yazım aşamasında.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Tag;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
